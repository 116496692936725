import { all, takeEvery, put, call } from 'redux-saga/effects'
import { notification } from 'antd'
import * as Services from 'services/business'
import actions from './actions'

export function* FETCH_ALL_BUSINESS({ payload: qryString }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.fetchAllBusiness, qryString)
  if (!response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data,
      },
    })
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* FETCH_ALL_ONBOARDING_REVIEW_BUSINESS({ payload: qryString }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.fetchAllOnboardingReviewBusiness, qryString)
  if (!response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data,
      },
    })
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* FETCH_BUSINESS_DETAIL({ payload: businessId }) {
  yield put({
    type: actions.SET_STATE_DETAIL,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.fetchBusinessDetails, businessId)
  if (!response.error) {
    yield put({
      type: actions.SET_STATE_DETAIL,
      payload: {
        loading: false,
        data: response.data,
      },
    })
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE_DETAIL,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* ACTIVE_DEACTIVE_BUSINESS({ payload }) {
  const { isActive, businessId, queryString } = payload

  const filteredData = {
    payload: {
      qryString: queryString,
    },
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.activeDeactivaeBusiness, businessId, isActive)
  if (!response.error) {
    yield call(FETCH_ALL_BUSINESS, filteredData)
    showSuccess(response.message)
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* ADD_BUSINESS_NOTES({ payload }) {
  const { notes, businessId } = payload
  yield put({
    type: actions.SET_STATE_USERS,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.addBusinessNotes, businessId, notes)
  if (!response.error) {
    yield put({
      type: actions.LIST_BUSINESS_NOTES,
      payload: {
        ...response.data,
        apiCall: true,
      },
    })
    showSuccess(response.message)
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE_USERS,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* FORCE_UPDATE_PASSWORD({ payload }) {
  const { businessId } = payload
  yield put({
    type: actions.SET_STATE_USERS,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.forceUpdatePassword, businessId)
  if (!response.error) {
    // TODO:
    //   yield call(FETCH_BUSINESS_USERS, { payload: { businessId } })
    showSuccess(response.message)
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE_USERS,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* DELETE_STRIPE_ACCOUNT({ payload }) {
  const { businessId, qryString } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.deleteStripeAccount, businessId)
  if (response.error === false) {
    yield call(FETCH_ALL_BUSINESS, { payload: { qryString } })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showSuccess(response.message)
  } else if (response.error || response.statusCode < 200 || response.statusCode > 299) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message, 'Oops')
  }
}

export function* CHANGE_STATEMENT_DESCRIPTOR({ payload }) {
  const { businessId, displayName } = payload

  yield put({
    type: actions.SET_STATE,
    payload: {
      allUsers: {
        loading: true,
      },
    },
  })
  const response = yield call(Services.changeStatementDescriptor, businessId, displayName)

  if (!response.error) {
    Notification.showSuccess(response.message)
  } else if (response.error) {
    Notification.showError(response.message)
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      allUsers: {
        loading: false,
      },
    },
  })
}

export function* SYNC_BUSINESS_STRIPE_DATA({ payload }) {
  const { businessId, isUser = false, userId } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.syncStripeAccount, businessId)
  if (response.error === false) {
    yield call(FETCH_ALL_BUSINESS, {
      payload: { qryString: `pageNo=1&pageSize=100${isUser ? `&userId=${userId}` : ''}` },
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showSuccess(response.message)
  } else if (response.error || response.statusCode < 200 || response.statusCode > 299) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message, 'Oops')
  }
}

export function* CLONE_BUSINESS({ payload }) {
  const { businessId, cloneBusinessId, legalName } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      cloneLoading: true,
    },
  })
  const response = yield call(Services.cloneBusiness, businessId, cloneBusinessId, legalName)
  if (response.error === false) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cloneLoading: false,
        clonedResponse: response,
      },
    })
    showSuccess(response.message)
  } else if (response.error || response.statusCode < 200 || response.statusCode > 299) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cloneLoading: false,
      },
    })
    showError(response.message, 'Oops')
  }
}

export function* RESTRICT_BUSINESS({ payload, setSubmitLoading }) {
  const { payoutStatus, debitCardCreationStatus, walletLoadStatus, selectedBusinesses } = payload
  const qryString = payload?.qryString
  let filteredData

  if (qryString) {
    filteredData = {
      payload: {
        qryString,
      },
    }
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const response = yield call(Services.restrictBusiness, {
    payoutStatus,
    debitCardCreationStatus,
    walletLoadStatus,
    selectedBusinesses,
  })
  if (!response.error) {
    if (setSubmitLoading) setSubmitLoading(false)
    showSuccess(response.message)
    if (qryString) {
      yield call(FETCH_ALL_BUSINESS, filteredData)
    }
  } else if (response.error) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
    showError(response.message)
  }
}

export function* TOGGLE_ONBOARDING({ payload }) {
  const { businessId, reqBody } = payload
  const response = yield call(Services.toggleOnboarding, businessId, reqBody)
  if (!response.error) {
    showSuccess(response.message)
  } else if (response.error || response.statusCode < 200 || response.statusCode > 299) {
    showError(response.message, 'Oops')
  }
}

function showError(msg, label) {
  notification.error({
    message: label || 'Try again',
    description: msg,
  })
}

function showSuccess(msg) {
  notification.success({
    message: 'Success',
    description: msg,
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_ALL_BUSINESS, FETCH_ALL_BUSINESS),
    takeEvery(actions.FETCH_BUSINESS_DETAIL, FETCH_BUSINESS_DETAIL),
    takeEvery(actions.ACTIVE_DEACTIVE_BUSINESS, ACTIVE_DEACTIVE_BUSINESS),
    takeEvery(actions.ADD_BUSINESS_NOTES, ADD_BUSINESS_NOTES),
    takeEvery(actions.FORCE_UPDATE_PASSWORD, FORCE_UPDATE_PASSWORD),
    takeEvery(actions.DELETE_STRIPE_ACCOUNT, DELETE_STRIPE_ACCOUNT),
    takeEvery(actions.CHANGE_STATEMENT_DESCRIPTOR, CHANGE_STATEMENT_DESCRIPTOR),
    takeEvery(actions.SYNC_BUSINESS_STRIPE_DATA, SYNC_BUSINESS_STRIPE_DATA),
    takeEvery(actions.CLONE_BUSINESS, CLONE_BUSINESS),
    takeEvery(actions.RESTRICT_BUSINESS, RESTRICT_BUSINESS),
    takeEvery(actions.TOGGLE_ONBOARDING, TOGGLE_ONBOARDING),
    takeEvery(actions.FETCH_ALL_ONBOARDING_REVIEW_BUSINESS, FETCH_ALL_ONBOARDING_REVIEW_BUSINESS)
  ])
}
