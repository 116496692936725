import apiClient from 'services/axios'

export async function fetchAllBusiness({ qryString }) {
  return apiClient
    .get(`${process.env.REACT_APP_API_GATEWAY_URL}/event-trigger/internal/businesses?${qryString}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function fetchAllOnboardingReviewBusiness({ qryString }) {
  return apiClient
    .get(`businesses/onboardingreview?${qryString}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function fetchBusinessDetails({ businessId }) {
  return apiClient
    .get(`businesses/${businessId}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function activeDeactivaeBusiness(businessId, isActive) {
  console.log(businessId, isActive)
  return apiClient
    .patch(`businesses/${businessId}`, { isActive })
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function addBusinessNotes(businessId, notes) {
  return apiClient
    .put(`businesses/${businessId}/notes`, notes)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function activeDeactiveUsers(isActive, businessId, userId) {
  return apiClient
    .patch(`businesses/${businessId}/users/${userId}`, { isActive })
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function toggleOnboarding(businessId, reqBody) {
  return apiClient
    .patch(`businesses/${businessId}/allow-onboarding`, reqBody)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function forceUpdatePassword(businessId) {
  return apiClient
    .patch(`businesses/${businessId}/forcepasswordchange`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function getPaymentReceiveds(businessId, qryString) {
  return apiClient
    .get(`businesses/${businessId}/payment?${qryString}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function sendPromotionalEmails(data) {
  return apiClient
    .post(`businesses/promotional/email`, data)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function deleteStripeAccount(businessId) {
  return apiClient
    .delete(`businesses/stripe/${businessId}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function generatePassword(userId) {
  return apiClient
    .get(`businesses/generate-password/${userId}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function changeStatementDescriptor(businessId, displayName) {
  return apiClient
    .patch(`businesses/${businessId}/legals`, displayName)
    .then(response => {
      return response
    })
    .catch(err => console.log(err))
}

export async function syncStripeAccount(businessId) {
  return apiClient
    .patch(`businesses/${businessId}/sync`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function fetchBusinessByName(qryString) {
  return apiClient
    .get(`businesses/searchByName?${qryString}`)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function cloneBusiness(businessId, cloneBusinessId, legalName) {
  return apiClient
    .patch(`businesses/${businessId}/clone`, { cloneBusinessId, legalName })
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}

export async function restrictBusiness(data) {
  return apiClient
    .put(`businesses/restrict`, data)
    .then(response => {
      return response.data
    })
    .catch(err => console.log(err))
}


export const fetchPaymentOnboardingSteps = (step,id) => {
  return apiClient
  .get(`businesses/${id}/onboarding?step=${step}`)
  .then(response => {
    return response.data
  })
  .catch(err => console.log(err))
}

export const onboardingDataSubmit = (data,id) => {
  return apiClient
  .post(`businesses/${id}/onboarding`,data)
  .then(response => {
    return response.data
  })
  .catch(err => console.log(err))

 }

 export const updateOnboardingDataStatus = (status,id) => {
  return apiClient
  .patch(`businesses/${id}/onboarding`,{"status" : status})
  .then(response => {
    return response.data
  })
  .catch(err => console.log(err))
}
